<template lang="pug">
  #need-license-photo.step-component
    icon#id-card(data="@icon/id-card.svg")
    h2 Now, let's take a picture of your driver's license or passport
    button#why(@click="showLicenseExplanation") Why do we need this?
    button.button(@click="gotoNext()") Continue
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
    showLicenseExplanation,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('complete')
}

function showLicenseExplanation() {
  this.$modal.open('WhyNeedLicensePhoto')
}
</script>
